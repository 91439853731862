import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../assets/icons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWishlist } from '../../utils/hooks/useWishlist';
import { formatPrice } from '../../utils/hooks/useUtil';
import { Modal } from '../modal/Modal';
import ProductDetail from '../../pages/ProductDetail';

function ProductCard({product, index}) {
  const { wishlistItems, toggleWishlistItem } = useWishlist();
  const itemExists = wishlistItems.find((item) => item.productID === product.productID);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const mainPicture = product.pictures.find(picture => picture.isMain);

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modalIsOpen]);

return (
  <>
  {product.inStock &&
        <div className='product-card' key={index}>   
            {/* <FontAwesomeIcon
              icon={itemExists ? icons.heartFull : icons.heart}
              onClick={() => toggleWishlistItem(product)}
            />    */}
          <div className='product-img' onClick={() => setModalIsOpen(true)}>
          {mainPicture ? (
                <img 
                  src={mainPicture.pictureUrl} 
                  alt={product.name}  
                />
              ) : (
                <p>Зображення відсутнє</p>
          )}
          </div>
          <div className='product-info' onClick={() => setModalIsOpen(true)} >
              <p>{product.brand}</p>
              <h3>{product.name}</h3>
              <p> {formatPrice(product.defaultPrice)}</p>
          </div>
      </div>
  }

  <Modal
    isOpen = {modalIsOpen}
    onClose = {()=>setModalIsOpen(false)}
  >
    <ProductDetail param={product.productID}/>
  </Modal>
  </>
);}

export default ProductCard;