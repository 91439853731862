import React, { useState, useEffect } from 'react';
import { icons } from '../../assets/icons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSize } from '../../utils/hooks/useSize';

function ProductSizes({ product }) {
  const { addSize, updateSize, deleteSize } = useSize();
  const [newSize, setNewSize] = useState({ size: '', price: '', quantity: '' });
  const [editedSizes, setEditedSizes] = useState(
    product.sizes.map((size) => ({ ...size }))
  ); // Создаем копию размеров продукта для редактирования

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setEditedSizes((prevSizes) =>
      prevSizes.map((size, i) =>
        i === index ? { ...size, [name]: value } : size
      )
    );
  };

  useEffect(() => {
    setEditedSizes(product.sizes.map((size) => ({ ...size })));
  }, [product]);

  return (
    <>
      <table className='size-table'>
        <thead>
          <tr>
            <th>Розмір</th>
            <th>Ціна</th>
            <th>Кількість</th>
            <th>Змінити</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                id="size"
                type="text"
                value={newSize.size}
                onChange={(e) =>
                  setNewSize((prevSize) => ({
                    ...prevSize,
                    size: e.target.value,
                  }))
                }
              />
            </td>
            <td>
              <input
                id="price"
                type="number"
                value={newSize.price}
                onChange={(e) =>
                  setNewSize((prevSize) => ({
                    ...prevSize,
                    price: e.target.value,
                  }))
                }
              />
            </td>
            <td>
              <input
                id="quantity"
                type="number"
                value={newSize.quantity}
                onChange={(e) =>
                  setNewSize((prevSize) => ({
                    ...prevSize,
                    quantity: e.target.value,
                  }))
                }
              />
            </td>
            <td>
              <button
                onClick={() => addSize({ ...newSize, productId: product.productID })}
              >
                ДОДАТИ
              </button>
            </td>
          </tr>
          {editedSizes.map((size, index) => (
            <tr key={index}>
              <td>
                <input
                  name="size"
                  type="text"
                  value={size.size}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </td>
              <td>
                <input
                  name="price"
                  type="number"
                  value={size.price}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </td>
              <td>
                <input
                  name="quantity"
                  type="number"
                  value={size.quantity}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </td>
              <td>
                <button onClick={(e) => deleteSize(size.productSizeID, e)}>
                  <FontAwesomeIcon icon={icons.trash}></FontAwesomeIcon>
                </button>
                <button
                  onClick={() => updateSize({ sizeId: size.productSizeID, size })}
                >
                  <FontAwesomeIcon icon={icons.save}></FontAwesomeIcon>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default ProductSizes;
