import React from 'react';
// import hero from "../assets/icons/hero1.png";
import Swiper from '../components/home/Swiper';
import ProductList from '../components/product/ProductList';

function Home({ isSearchPage, searchInput }) {
  return (
    <>
      <div className={`search container ${isSearchPage ? 'active' : ''}`}>
        <div className='search-resaults'>
          <h2>Результати пошуку</h2>
          <ProductList searchInput={searchInput} />
        </div>
      </div>
      
      {!isSearchPage && (
        <>
          <div className='hero'>
            <div className='hero-circle'> </div>
          </div>
          <div className='main container'>
            <Swiper />
          </div>
        </>
      )}
    </>
  );
}

export default Home;
