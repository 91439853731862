import axios from 'axios';
import { variables } from './variables.js';

const API_URL = variables.ORDER_API

const getOrders = async () => {
  const response = await axios.get(API_URL);
  return response.data;
}

const createOrder = async (order) => {
  const response = await axios.post(API_URL, order);
  return response;
}

const updateOrder = async (orderId, order) => {
  const response = await axios.post(`${API_URL}/update/${orderId}`, order);
  return response;
}

const deleteOrder = async (orderId) => {
  const response = await axios.post(`${API_URL}/delete/${orderId}`);
  return response.data;
}

export default {
    getOrders,
    createOrder,
    updateOrder,
    deleteOrder
};