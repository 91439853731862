import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { ReactComponent as IconNext } from './chevron-right-solid.svg';
import { ReactComponent as IconPrev } from './chevron-left-solid.svg';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import './Swiper.scss';
import axios from 'axios';
import { variables } from '../../utils/api/variables';
import ProductList from '../../components/product/ProductList';

const ProductSlider = () => {
  const [categories, setCategories] = useState([]);
  const swiperRef = useRef(null);
  const API_URL = variables.BASE_URL;

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}category`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <div className="swiper-main-container">
      <div className="swiper-inner-container">
        {categories.length > 0 && (
          <Swiper
            ref={swiperRef}
            spaceBetween={50}
            slidesPerView={1}
            loop={true}
            navigation={false}
            pagination={{ clickable: true }}
            modules={[Navigation, Pagination]}
            autoHeight={true}
          >
            {categories.map((category, index) => (
              <SwiperSlide key={index}>
                <div>
                  <h2 style={{marginLeft: '0.5rem'}} >{category.name}</h2>
                  <div className="products-container">
                    <ProductList categoryId={category.categoryID} />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
  
      {categories.length > 0 && (
        <>
          <div className="swiper-button-next" onClick={handleNext}>
            <IconNext />
          </div>
          <div className="swiper-button-prev" onClick={handlePrev}>
            <IconPrev />
          </div>
        </>
      )}
    </div>
  );
};

export default ProductSlider;
