import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './imageModal.scss'
import { ReactComponent as IconPlus } from '../../assets/icons/plus-solid.svg';
import { ReactComponent as IconMinus } from '../../assets/icons/minus-solid.svg';
import { ReactComponent as IconClose } from '../../assets/icons/xmark-solid.svg';

const ImageModal = ({ isOpen, onClose, imageSrc }) => {

    const [showModal, setShowModal] = useState(false);
    const [scale, setScale] = useState(1);
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
    const [canDrag, setCanDrag] = useState(false);
    const imgRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setShowModal(true);
            setScale(1);
            setPosition({ x: 0, y: 0 });
        } else {
            const timer = setTimeout(() => setShowModal(false), 300);
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    const zoomIn = () => {
        setScale((prevScale) => {
            const newScale = Math.min(prevScale + 0.4, 5);
            if (newScale > 1) {
                setCanDrag(true);
            }
            return newScale;
        });
    };

    const zoomOut = () => {
        setScale((prevScale) => {
            const newScale = Math.max(prevScale - 0.4, 0.5);
            if (newScale <= 1) {
                setCanDrag(false);
                setPosition({ x: 0, y: 0 });
            }
            return newScale;
        });
    };

    const handleWheel = (e) => {
        e.preventDefault();
        if (e.deltaY < 0) {
            zoomIn();
        } else {
            zoomOut();
        }
    };

    const handleMouseDown = (e) => {
        if (canDrag) {
            e.preventDefault();
            setIsDragging(true);
            setInitialPosition({
                x: e.clientX - position.x,
                y: e.clientY - position.y,
            });
        }
    };

    const handleMouseMove = (e) => {
        if (isDragging && canDrag) {
            const moveX = e.clientX - initialPosition.x;
            const moveY = e.clientY - initialPosition.y;
            const limitedPosition = limitPosition(moveX, moveY);
            setPosition(limitedPosition);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleTouchStart = (e) => {
        if (canDrag) {
            e.preventDefault();
            const touch = e.touches[0];
            setIsDragging(true);
            setInitialPosition({
                x: touch.clientX - position.x,
                y: touch.clientY - position.y,
            });
        }
    };

    const handleTouchMove = (e) => {
        if (isDragging && canDrag) {
            const touch = e.touches[0];
            const moveX = touch.clientX - initialPosition.x;
            const moveY = touch.clientY - initialPosition.y;
            const limitedPosition = limitPosition(moveX, moveY);
            setPosition(limitedPosition);
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    const limitPosition = (x, y) => {
        const img = imgRef.current;
        const container = img.parentElement;

        const imgWidth = img.width * scale;
        const imgHeight = img.height * scale;

        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;

        let limitedX = x;
        let limitedY = y;

        // Ограничение по горизонтали
        if (imgWidth > containerWidth) {
            const maxOffsetX = (imgWidth - containerWidth) / 2 / scale;
            limitedX = Math.max(Math.min(x, maxOffsetX), -maxOffsetX);
        } else {
            limitedX = 0; // Центрируем изображение по горизонтали
        }

        // Ограничение по вертикали
        if (imgHeight > containerHeight) {
            const maxOffsetY = (imgHeight - containerHeight) / 2 / scale;
            limitedY = Math.max(Math.min(y, maxOffsetY), -maxOffsetY);
        } else {
            limitedY = 0; // Центрируем изображение по вертикали
        }

        return { x: limitedX, y: limitedY };
    };

    if (!showModal) return null;



    return ReactDOM.createPortal(
      <div className={`modal-image-overlay ${isOpen ? 'show' : ''}`} onClick={onClose}>
          <div className='modal-image-content' 
            onClick={(e) => e.stopPropagation()}
            onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              onWheel={handleWheel}
          >
              <img src={imageSrc} ref={imgRef} style={{ 
                transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
                transition: isDragging ? 'none' : 'transform 0.3s ease',
            }}/>
              <div className="button-container">
                    <button className="zoom-button zoom-in" onClick={zoomIn}>
                        <IconPlus />
                    </button>
                    <button className="zoom-button zoom-out" onClick={zoomOut}>
                        <IconMinus />
                    </button>
                </div>
          </div>
      </div>,
      document.body
  );
};

export default ImageModal;