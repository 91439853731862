import React, { useState } from 'react';
import Shipping from '../components/checkout/Shipping';
import Confirmation from '../components/checkout/Confirmation';
import Complete from "../components/checkout/Complete";
import toast, { Toaster } from 'react-hot-toast';

const tabs = ["Відправлення", "Підтвердження", "Готово"];

function Checkout({ goBack, onClose, items }) {
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState({
    deliveryType: '',
    name: '',
    surname: '',
    phone: '',
    email: '',
    address: '',
    city: ''
  });
  const [errors, setErrors] = useState({});

  const orderItems = items.map(item => ({
    ProductId: item.product.productID,
    Quantity: item.quantity,
    ProductSizeID: item.sizeId,
  }));

  const order = {
    UserName: data.name,
    UserSurname: data.surname,
    UserPhone: data.phone,
    UserEmail: data.email,
    DeliveryType: data.deliveryType,
    Address: data.address,
    NPCity: data.city,
    TotalPrice: data.totalPrice,
    OrderItems: orderItems,
  };

  const isLastTab = activeTab === tabs.length - 1;
  const isSecondLastTab = activeTab === tabs.length - 2;

  const updateField = (field, value) => {
    setData(prevData => ({
      ...prevData,
      [field]: value,
    }));
  };

  const isShippingValid = () => {
    const newErrors = {};

    if (!data.name) newErrors.name = 'k';
    if (!data.surname) newErrors.surname = 'k';
    if (!data.phone || data.phone.length !== 12) newErrors.phone = 'k';
    if (!data.email || !/\S+@\S+\.\S+/.test(data.email)) newErrors.email = 'k';
    if (!data.deliveryType) newErrors.deliveryType = 'k';
    if (data.deliveryType === 'Нова Пошта' && !data.city) newErrors.city = 'k';
    if (!data.address) newErrors.address = 'k';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleNextClick = () => {
    if (activeTab === 0 && !isShippingValid()) {
      toast.error('Перевірте інформацію!', {
        duration: 2000,
        position: 'top-left',
      });
      return;
    }
    setActiveTab(activeTab + 1);
  };

  return (
    <div className='checkout'>
      <div className='checkout-container'>
        <nav className='checkout-nav'>
          {tabs.map((tab, index) => (
            <React.Fragment key={tab}>
              <div className={`checkout-tab ${index === activeTab ? 'active' : ''}`}>
                <span className={`${index < activeTab ? 'completed' : ''}`}>
                  {index+1}
                </span>
                <p>{tab}</p>
              </div>
              {index < tabs.length - 1 && (
                <div className={`checkout-tab-line ${index < activeTab ? 'completed' : ''}`} />
              )}
            </React.Fragment>
          ))}
        </nav>
        <div className="checkout-content">
          {activeTab === 0 && <Shipping data={data} updateField={updateField} errors={errors} />}
          {activeTab === 1 && <Confirmation data={data} updateField={updateField} />}
          {activeTab === 2 && <Complete onClose={onClose} order={order} setActiveTab={setActiveTab} />}
        </div>
        <div className="checkout-bottom">
          {!isLastTab ? (
            activeTab === 0 ? (
              <button className='second-button' onClick={goBack}>Назад</button>
            ) : (
              <button className='second-button' onClick={() => setActiveTab(activeTab - 1)}>Назад</button>
            )
          ) : null}
          {!isLastTab && (
            <button onClick={handleNextClick}>Далі</button>
          )}
        </div>
      </div>
      <Toaster /> {/* Добавляем этот компонент для отображения тостов */}
    </div>
  );
}

export default Checkout;
