import { faUser, faCircleCheck, faHeart as faHeartRegular, faSave, faCreditCard, faEdit } from '@fortawesome/free-regular-svg-icons';
import { faBasketShopping, faSearch, faBars, faEnvelope, faLock, faTimes, faHeart as faHeartSolid, faSlidersH  } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { faTelegram, faInstagram, faViber } from '@fortawesome/free-brands-svg-icons';

export const icons = {
  user: faUser,
  heart: faHeartRegular,
  heartFull: faHeartSolid,
  cart: faBasketShopping,
  search: faSearch,
  edit: faEdit,
  hamburger: faBars,
  email: faEnvelope,
  lock: faLock,
  trash: faTimes,
  save: faSave,
  creditCard: faCreditCard,
  // idCard: faAddressCard,
  check: faCircleCheck,
  filter: faSlidersH,
  addressCard: faAddressCard,
  telegram: faTelegram,
  instagram: faInstagram,
  viber: faViber
};
